import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>About Me</h2>
          <p>Min is a Financial Architect who focuses on holistic Financial
Planning to serve the Burmese community in Singapore.</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">MY STORY</h3>
          <p>
          Min is a Financial Architect who focuses on holistic Financial Planning to serve the burmese community in Singapore.
          I pursued the financial service industry after I graduated from school. Being in this Industry, reconnected me with my roots, cultures and the Burmese community in Singapore. I feel it is extremely important for us to reconnect with our community when we are overseas. So that we can help each other in tough times and celebrate the good news.
          </p>

          <h3 className="major">My Inspiration to connect with the growing Burmese community in Singapore</h3>
          <p>
          I feel that everyone should have two types of Education - Own Professional Education and Financial Education. I strongly feel that our people thrive well in their Professional Education and Career. However, many are neglecting the strong importance of Financial Education.  
          I want to serve and educate the Burmese community in Singapore with abundant Financial Knowledge such as Emergency cash account and Growth Account.
          </p>
          <p>
          Apart from sharing about financial education, having lived in Singapore for many years, 
          I have come to realize that some of us came here alone and we don’t 
          have many family members or many friends here in Singapore. 
          We became very independent and that actually led to some of us being lonely. 
          We only look forward to our job and salary. Somehow in our lives we became very robotic. 
          I strongly believe that life itself has more meaning than just work and salary.
          </p>
          <p>
          Hence, I would like to create a wonderful connection among the burmese community. 
          Connecting everyone together so that we all can come together as a community to participate in different activities. Within the community, 
          I hope we all can learn from one another and we all grow together as a community.
          </p>

          <h3 className="major">My Vision within this growing Burmese community in Singapore</h3>
          <p>
              <ul>
                <li>To Connect with 20,000 out of 200,000 Burmese Individuals working in Singapore</li>
                <li>To make sure these 20,000 of them are well adequate with their financial knowledge</li>
                <li>To make sure these 20,000 of them feel warm, welcome, enjoy life and grow together.</li>
              </ul>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
